import React from 'react';
import { bool, func, string } from 'prop-types';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Box,
  Heading,
  Divider,
  Text,
  Button,
  Center,
} from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';

import useFamily from '@/hooks/useFamily';

import { BG_ALTERNATIVE, BUTTON_PROPS } from '@/constants/colors';
import { DEFAULT_LANGUAGE } from '@/constants/feature-flags';
import { txtForm } from './locales';

function GuestInformation({ onClose, visible, lang }) {
  const isFamily = useFamily();

  const renderTextInfo = ({ text, ...rest }) => {
    return (
      <Box marginTop="8px" display="flex" {...rest}>
        <FaInfoCircle
          size={14}
          style={{ marginRight: '4px', color: BG_ALTERNATIVE, width: '14px', marginTop: '4px' }}
        />
        <Text width="calc(100% - 14px)" dangerouslySetInnerHTML={{ __html: text }} />
      </Box>
    );
  };

  return (
    <Drawer size="full" placement="bottom" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody
          bgColor="bgPrimary"
          bgSize="cover"
          padding="42px 32px"
        >
          <Box bgColor="bgSecondary" padding="42px 24px" borderRadius="16px" boxShadow="xl">
            <Heading size="2xl" fontWeight="normal">
              {isFamily ? 'Family' : 'Guest'} <br /> Information
            </Heading>
            <Divider
              width="80%"
              opacity={1}
              height="2px"
              borderColor="bgAlternative"
              borderBottomWidth="1px"
              margin="16px 0"
              marginLeft="-24px"
            />
            {/* Main Information */}
            <Text fontWeight="bold">HOTEL DETAILS</Text>
            <Text
              dangerouslySetInnerHTML={{
                __html: `Complimentary ${isFamily ? '2' : '1'
                  } night stay (breakfast included) will be provided at: <br /> FOUR
              POINTS BY SHERATON BALI.`,
              }}
            />

            <Text fontSize="sm" fontStyle="italic" marginBottom="16px">
              Jl. Raya Uluwatu, Banjar Giri Dharma Ungasan
            </Text>

            {renderTextInfo({
              text: `Check in: <br />  ${isFamily ? 'Friday, June 17' : 'Saturday, June 18'
                }<sup>th</sup>, 2022 | 14.00 WITA`,
              marginTop: '8px',
            })}
            {renderTextInfo({
              text: `Check out: <br /> Sunday, June 19<sup>th</sup> 2022 | 12:00 WITA`,
            })}
            {/* Shuttle Schedule */}
            <Text marginTop="16px" fontWeight="bold">
              SHUTTLE SCHEDULE
            </Text>
            <Text>We will provide a shuttle from Four Points by Sheraton Bali to each venue.</Text>
            <Text>
              <br />
              Please pay attention to the schedule below:
            </Text>
            {renderTextInfo({
              marginTop: '16px',
              text: `09.00 WITA<br/> For Holy Matrimony <br /> Depart from Four Points by Sheraton Bali to St. Silvester Church, Pecatu`,
            })}
            {isFamily &&
              renderTextInfo({
                text: `16.00 WITA<br />For Teapai Ceremony <br /> Depart from Four Points by Sheraton Bali to Villa Plenilunio`,
              })}
            {renderTextInfo({
              text: `17.00 WITA<br /> For Sunset Cocktail & Dinner Reception <br /> Depart from Four Points by Sheraton Bali to Villa Plenilunio`,
            })}
            <Center>
              <Button
                {...BUTTON_PROPS}
                size="md"
                fontWeight="normal"
                letterSpacing="2px"
                textTransform="uppercase"
                bgColor="bgPrimary"
                color="mainColorText"
                fontFamily="body"
                marginTop="32px"
                onClick={onClose}
              >
                {txtForm.back[lang]}
              </Button>
            </Center>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

GuestInformation.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
};

GuestInformation.defaulProps = {
  lang: DEFAULT_LANGUAGE,
};

export default GuestInformation;
