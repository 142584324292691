import { css } from '@emotion/css';

export const styleRsvp = css`
.rsvp-wrapper {
  padding: 24px 40px;
}
.flower-left {
  position: absolute; 
  top: -25px;
  left: -145px;
  z-index: 5;
}
.flower-left-img {
  width: 250px;
}
.flower-right {
  position: absolute; 
  top: 100px;
  right: -175px;
  transform: rotateY(180deg)
}
.flower-right-img {
  width: 250px;
}

@media screen and (max-width:420px) {
  .rsvp-wrapper {
    padding: 24px 24px;
  }
  .flower-left {
    position: absolute; 
    top: -20px;
    left: -130px;
  }
  .flower-left-img {
    width: 200px;
  }
  .flower-right {
    position: absolute; 
    top: 100px;
    right: -140px;
  }
  .flower-right-img {
    width: 200px;
  }
}`